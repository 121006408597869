import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {AppState} from '../states/app.state';
import {
  fakturierungsbelegEntitiesReducer,
  initialFakturierungsbelegEntitiesState
} from './fakturierungsbeleg-entities.reducers';
import {absenderEntitiesReducer, initialAbsenderEntitiesState} from './absender-entities.reducers';
import {initialProduktEntitiesState, produktEntitiesReducer} from './produkt-entities.reducers';
import {fakturierungsbelegTableReducer, initialFakturierungsbelegTableState} from './fakturierungsbeleg-table.reducer';
import {
  deleteFakturierungsbelegDialogReducer,
  initialDeleteFakturierungsbelegDialogState
} from './delete-fakturierungsbeleg-dialog.reducers';
import {deleteAbsenderDialogReducer, initialDeleteAbsenderDialogState} from './delete-absender-dialog.reducers';
import {initialJfLibState, jfLibReducers} from '@adnova/jf-ng-components';
import {belegeEmptyStateDialogReducer, initialBelegeEmptyStateDialogState} from './belege-empty-state-dialog.reducers';
import {absenderTableReducer, initialAbsenderTableState} from './absender-table.reducer';
import {initialProduktTableState, produktTableReducer} from './produkt-table.reducer';
import {deleteProduktDialogReducer, initialDeleteProduktDialogState} from './delete-produkt-dialog.reducers';
import {initialUploadLogoDialogState, uploadLogoDialogReducer} from './upload-logo-dialog.reducers';
import {produktDialogReducer, initialCreateProduktDialogState} from './produkt-dialog.reducer';
import {createAbsenderDialogReducer, initialCreateAbsenderDialogState} from './create-absender-dialog.reducers';
import {
  absenderLeistungsempfaengerReducer,
  initialAbsenderLeistungsempfaengerState
} from './absender-leistungsempfaenger.reducers';
import {fakturierungsbelegFormReducer, initialFakturierungsbelegFormState} from './fakturierungsbeleg-form.reducers';


export const initialAppState: AppState = {
  ...initialJfLibState,
  fakturierungsbelegEntities: initialFakturierungsbelegEntitiesState,
  fakturierungsbelegTable: initialFakturierungsbelegTableState,
  absenderEntities: initialAbsenderEntitiesState,
  absenderTable: initialAbsenderTableState,
  absenderLeistungsempfaenger: initialAbsenderLeistungsempfaengerState,
  produktEntities: initialProduktEntitiesState,
  produktTable: initialProduktTableState,
  produktDialog: initialCreateProduktDialogState,
  deleteFakturierungsbelegDialog: initialDeleteFakturierungsbelegDialogState,
  uploadLogoDialog: initialUploadLogoDialogState,
  createAbsenderDialog: initialCreateAbsenderDialogState,
  deleteAbsenderDialog: initialDeleteAbsenderDialogState,
  deleteProduktDialog: initialDeleteProduktDialogState,
  belegeEmptyStateDialog: initialBelegeEmptyStateDialogState,
  fakturierungsbelegForm: initialFakturierungsbelegFormState,
};

export const reducers: ActionReducerMap<AppState> = {
  ...jfLibReducers,
  fakturierungsbelegEntities: fakturierungsbelegEntitiesReducer,
  fakturierungsbelegTable: fakturierungsbelegTableReducer,
  absenderEntities: absenderEntitiesReducer,
  absenderTable: absenderTableReducer,
  absenderLeistungsempfaenger: absenderLeistungsempfaengerReducer,
  produktEntities: produktEntitiesReducer,
  produktTable: produktTableReducer,
  produktDialog: produktDialogReducer,
  deleteFakturierungsbelegDialog: deleteFakturierungsbelegDialogReducer,
  uploadLogoDialog: uploadLogoDialogReducer,
  createAbsenderDialog: createAbsenderDialogReducer,
  deleteAbsenderDialog: deleteAbsenderDialogReducer,
  deleteProduktDialog: deleteProduktDialogReducer,
  belegeEmptyStateDialog: belegeEmptyStateDialogReducer,
  fakturierungsbelegForm: fakturierungsbelegFormReducer,
};

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [logger] : [];
