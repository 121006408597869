import {FakturierungsbelegFormState} from '../states/fakturierungsbeleg-form.state';
import {createReducer, on} from '@ngrx/store';
import {FakturierungsbelegFormActions} from '../actions/fakturierungsbeleg-form.actions';


export const initialFakturierungsbelegFormState: FakturierungsbelegFormState = {
  logoUrl: null,
};

export const fakturierungsbelegFormReducer = createReducer(
  initialFakturierungsbelegFormState,

  on(
    FakturierungsbelegFormActions.selectLeistungsempfaenger,
    (state, {absenderId}) => {
      return {
        ...state,
        absenderId,
      };
    }
  ),


  on(FakturierungsbelegFormActions.getLogoSuccess, (state, {logoUrl}) => ({
    ...state,
    logoUrl,
  })),

  on(FakturierungsbelegFormActions.saveLogoSuccess, (state, {logoUrl}) => ({
    ...state,
    logoUrl,
  })),

  on(FakturierungsbelegFormActions.deleteLogoSuccess, (state) => ({
    ...state,
    logoUrl: null,
  })),

  on(FakturierungsbelegFormActions.releaseLogoFromMemory, (state) => ({
    ...state,
    logoUrl: null,
  })),

);
