/**
 * Fakturierung
 * API für Fakturierung
 *
 * The version of the OpenAPI document: ${project.version}
 * Contact: support@landdata.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { CountFakturierungsbelegResponseDTO } from '../model/models';
import { ErrorMessageDTO } from '../model/models';
import { FakturierungsbelegDTO } from '../model/models';
import { FilterBelegDTO } from '../model/models';
import { LogoBinaryDTO } from '../model/models';
import { PageableFakturierungsbelegDTO } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class FakturierungsbelegService {

    protected basePath = 'http://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Lesen der Anzahl aller Fakturierungsbelege
     * @param inhaberId Id die einen Inhaber identifiziert. 
     * @param fakturierungsbelegFilter Objekt für die Filterung von Fakturierungsbelegen. 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public countFakturierungsbelege(inhaberId: string, fakturierungsbelegFilter?: FilterBelegDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CountFakturierungsbelegResponseDTO>;
    public countFakturierungsbelege(inhaberId: string, fakturierungsbelegFilter?: FilterBelegDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CountFakturierungsbelegResponseDTO>>;
    public countFakturierungsbelege(inhaberId: string, fakturierungsbelegFilter?: FilterBelegDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CountFakturierungsbelegResponseDTO>>;
    public countFakturierungsbelege(inhaberId: string, fakturierungsbelegFilter?: FilterBelegDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling countFakturierungsbelege.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fakturierungsbelegFilter !== undefined && fakturierungsbelegFilter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fakturierungsbelegFilter, 'fakturierungsbelegFilter');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<CountFakturierungsbelegResponseDTO>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/fakturierungsbelege/count`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Löschen eines Fakturierungsbelegs.
     * Löscht einen Fakturierungsbeleg zu der UUID. 
     * @param inhaberId Id die einen Inhaber identifiziert. 
     * @param fakturierungsbelegId Id die einen Fakturierungsbeleg identifiziert. 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFakturierungsbeleg(inhaberId: string, fakturierungsbelegId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteFakturierungsbeleg(inhaberId: string, fakturierungsbelegId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteFakturierungsbeleg(inhaberId: string, fakturierungsbelegId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteFakturierungsbeleg(inhaberId: string, fakturierungsbelegId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling deleteFakturierungsbeleg.');
        }
        if (fakturierungsbelegId === null || fakturierungsbelegId === undefined) {
            throw new Error('Required parameter fakturierungsbelegId was null or undefined when calling deleteFakturierungsbeleg.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/fakturierungsbelege/${encodeURIComponent(String(fakturierungsbelegId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Löschen eines Logos.
     * Löscht das Logo für den angegebenen Inhaber. 
     * @param inhaberId Id die einen Inhaber identifiziert. 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteLogo(inhaberId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteLogo(inhaberId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteLogo(inhaberId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteLogo(inhaberId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling deleteLogo.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/logo`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Abruf eines einzelnen Fakturierungsbelegs.
     * Laden eines Fakturierungsbelegs zu der UUID. 
     * @param inhaberId Id die einen Inhaber identifiziert. 
     * @param fakturierungsbelegId Id die einen Fakturierungsbeleg identifiziert. 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFakturierungsbeleg(inhaberId: string, fakturierungsbelegId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<FakturierungsbelegDTO>;
    public getFakturierungsbeleg(inhaberId: string, fakturierungsbelegId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<FakturierungsbelegDTO>>;
    public getFakturierungsbeleg(inhaberId: string, fakturierungsbelegId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<FakturierungsbelegDTO>>;
    public getFakturierungsbeleg(inhaberId: string, fakturierungsbelegId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling getFakturierungsbeleg.');
        }
        if (fakturierungsbelegId === null || fakturierungsbelegId === undefined) {
            throw new Error('Required parameter fakturierungsbelegId was null or undefined when calling getFakturierungsbeleg.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<FakturierungsbelegDTO>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/fakturierungsbelege/${encodeURIComponent(String(fakturierungsbelegId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lesen aller Fakturierungsbelege
     * @param inhaberId Id die einen Inhaber identifiziert. 
     * @param fakturierungsbelegFilter Objekt für die Filterung von Fakturierungsbelegen. 
     * @param fakturierungsbelegPageable Objekt für das OffSet, Limit und die Sortierung beim Laden von Fakturierungsbelegen. 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readFakturierungsbelege(inhaberId: string, fakturierungsbelegFilter?: FilterBelegDTO, fakturierungsbelegPageable?: PageableFakturierungsbelegDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<FakturierungsbelegDTO>>;
    public readFakturierungsbelege(inhaberId: string, fakturierungsbelegFilter?: FilterBelegDTO, fakturierungsbelegPageable?: PageableFakturierungsbelegDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<FakturierungsbelegDTO>>>;
    public readFakturierungsbelege(inhaberId: string, fakturierungsbelegFilter?: FilterBelegDTO, fakturierungsbelegPageable?: PageableFakturierungsbelegDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<FakturierungsbelegDTO>>>;
    public readFakturierungsbelege(inhaberId: string, fakturierungsbelegFilter?: FilterBelegDTO, fakturierungsbelegPageable?: PageableFakturierungsbelegDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling readFakturierungsbelege.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fakturierungsbelegFilter !== undefined && fakturierungsbelegFilter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fakturierungsbelegFilter, 'fakturierungsbelegFilter');
        }
        if (fakturierungsbelegPageable !== undefined && fakturierungsbelegPageable !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fakturierungsbelegPageable, 'fakturierungsbelegPageable');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<FakturierungsbelegDTO>>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/fakturierungsbelege/`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Abrufen des Logos.
     * Ruft das Logo des angegebenen Inhabers ab. 
     * @param inhaberId Id die einen Inhaber identifiziert. 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readLogo(inhaberId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<LogoBinaryDTO>;
    public readLogo(inhaberId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<HttpResponse<LogoBinaryDTO>>;
    public readLogo(inhaberId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<HttpEvent<LogoBinaryDTO>>;
    public readLogo(inhaberId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling readLogo.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<LogoBinaryDTO>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/logo`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Hochladen eines Logos.
     * Lädt ein Logo hoch und speichert es im Backend. 
     * @param inhaberId Id die einen Inhaber identifiziert. 
     * @param logoBinaryDTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateLogo(inhaberId: string, logoBinaryDTO: LogoBinaryDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<boolean>;
    public updateLogo(inhaberId: string, logoBinaryDTO: LogoBinaryDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<boolean>>;
    public updateLogo(inhaberId: string, logoBinaryDTO: LogoBinaryDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<boolean>>;
    public updateLogo(inhaberId: string, logoBinaryDTO: LogoBinaryDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling updateLogo.');
        }
        if (logoBinaryDTO === null || logoBinaryDTO === undefined) {
            throw new Error('Required parameter logoBinaryDTO was null or undefined when calling updateLogo.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/octet-stream'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<boolean>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/logo`,
            logoBinaryDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
