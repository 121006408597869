import {createSelector} from '@ngrx/store';
import {AbsenderEntitiesSelectors} from './absender-entities.selectors';
import {AppState} from '../states/app.state';


export class FakturierungsbelegFormSelectors {
  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    absenderId: (state: AppState) => state.fakturierungsbelegForm.absenderId || '',
    logoUrl: (state: AppState) => state.fakturierungsbelegForm.logoUrl,
  };

  public static absenderDto = createSelector(
    this.select.absenderId,
    AbsenderEntitiesSelectors.all,
    (id, absender) =>
      AbsenderEntitiesSelectors.absenderById(id).projector(absender)
  );

  public static logoUrl = createSelector(
    this.select.logoUrl,
    (logoUrl) => logoUrl
  );

}
