import {createAction, props} from '@ngrx/store';


export class FakturierungsbelegFormActions {

  public static selectLeistungsempfaenger = createAction(
    '[Fakturierungsbeleg Form] Select Leistungsempfänger',
    props<{
      absenderId?: string,
    }>(),
  );

  public static getLogo = createAction(
    '[Fakturierunsgbeleg Form] Get logo.',
    props<{ inhaberId: string }>()
  );

  public static getLogoSuccess = createAction(
    '[Fakturierunsgbeleg Form] Get logo successfully.',
    props<{ logoUrl: string | null }>()
  );

  public static getLogoFailure = createAction(
    '[Fakturierunsgbeleg Form] Get logo failed.',
    props<{ error: any }>(),
  );

  public static saveLogo = createAction(
    '[Fakturierunsgbeleg Form] Save logo.',
    props<{
      inhaberId: string,
      logoUrl: string,
    }>(),
  );

  public static saveLogoSuccess = createAction(
    '[Fakturierunsgbeleg Form] Save logo successfully.',
    props<{ logoUrl: string }>()
  );

  public static saveLogoFailure = createAction(
    '[Fakturierunsgbeleg Form] Save logo failed.',
    props<{ error: any }>()
  );

  public static deleteLogo = createAction(
    '[Fakturierunsgbeleg Form] Delete logo.',
    props<{ inhaberId: string }>()
  );

  public static deleteLogoSuccess = createAction(
    '[Fakturierunsgbeleg Form] Delete logo successfully.',
  );

  public static deleteLogoFailure = createAction(
    '[Fakturierunsgbeleg Form] Delete logo failed.',
    props<{ error: any }>()
  );

  public static releaseLogoFromMemory = createAction(
    '[Fakturierunsgbeleg Form] Release logo from browser memory.',
    props<{ logoUrl?: string }>()
  );
}
